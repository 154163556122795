<template>
  <div class="record">
    <div class="record-content">
      <div class="record-header is-flex has-margin-bottom-tiny">
        <div>#{{ item.key }}</div>
        <div class="is-meta">{{ item.created_date | formatDateTime }}</div>
      </div>
      <div class="record-title is-flex is-flex-align-center is-flex-space-between has-margin-bottom-small">
        <a class="has-text-medium" @click="$router.push('/support/issue/' + item.key)">
          <span class="is-flex is-flex-align-center">
            <span class="has-margin-right-small">
              <b-tag v-if="item.status == 'new'" type="is-warning">Novo</b-tag>
              <b-tag v-else-if="item.status == 'open'" type="is-primary">Aberto</b-tag>
              <b-tag v-else type="is-black">Fechado</b-tag>
            </span>
            <v-clamp autoresize :max-lines="1">{{ item.subject }}</v-clamp>
          </span>
        </a>
      </div>
      <div class="record-meta is-small">
        <v-clamp class="do-break-word" autoresize :max-lines="2">{{ item.description | sanitizeAnyHtml }}</v-clamp>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
}
</script>
