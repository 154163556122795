import Api from '@/services/Api'

export default {
  submitIssue(data) {
    return Api()
      .post('/v1/issue/new', data)
      .then(response => {
        return response.data
      })
  },

  updateIssueStatus(issue) {
    return Api()
      .post('/v1/issue/' + issue.key + '/status', issue)
      .then(response => {
        return response.data
      })
  },

  addMessage(issueKey, data) {
    return Api()
      .post('/v1/issue/' + issueKey + '/message', data)
      .then(response => {
        return response.data
      })
  },

  downloadIssueFile(fileKey, fileName) {
    return Api()
      .get('/v1/issue/file/' + fileKey + '/' + fileName + '?dl=0', {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
  },

  downloadMessageFile(fileKey, fileName) {
    return Api()
      .get('/v1/issue/message/file/' + fileKey + '/' + fileName + '?dl=0', {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
  },

  getIssue(issueKey) {
    return Api()
      .get('/v1/issue/' + issueKey)
      .then(response => {
        return response.data
      })
  },

  getAllAssignedIssues(status) {
    const params = {
      status: status,
    }
    return Api()
      .get('/v1/issues', { params })
      .then(response => {
        return response.data
      })
  },

  getIssuesAsOwner(userKey) {
    return Api()
      .get('/v1/issues', {
        params: {
          user_key: userKey,
        },
      })
      .then(response => {
        return response.data
      })
  },
}
