<template>
  <MainLayout back-to="/" :title="$t('support.title')" :short-title="$t('support.title')">
    <template v-slot:header-right-mobile>
      <button class="button is-text" @click="isNewIssueModalActive = true">
        <faicon icon="plus"></faicon>
      </button>
    </template>
    <template v-slot:content>
      <div class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>{{ $t('support.myTickets') }}</h2>
          </div>
          <div v-if="issues" class="panel-header-nav is-hidden-mobile">
            <b-button type="is-text" @click="isNewIssueModalActive = true">
              {{ $t('support.newTicket') }}
            </b-button>
          </div>
        </div>
        <div v-if="!isLoading" class="panel-body">
          <div v-if="issues.length">
            <RecordIssue v-for="issue in issues" :key="issue.key" :item="issue"></RecordIssue>
          </div>
          <div v-else class="empty-hero-box">
            <p class="has-margin">{{ $t('support.noTickets') }}.</p>
            <h3 class="has-margin">{{ $t('support.needHelp') }}?</h3>
            <button class="button is-primary" @click="isNewIssueModalActive = true">
              {{ $t('support.newTicket') }}
            </button>
          </div>
        </div>
        <ContentLoader v-else />
      </div>
    </template>
    <template v-slot:modals>
      <ModalAddEditIssue :show.sync="isNewIssueModalActive" @close="closeNewIssueModal" />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout'

import ContentLoader from '@/components/utilities/ContentLoader'
import RecordIssue from '@/components/records/RecordIssue'
import SupportService from '@/services/SupportService'
import ModalAddEditIssue from '@/components/modals/ModalAddEditIssue'

export default {
  components: {
    MainLayout,
    RecordIssue,
    ContentLoader,
    ModalAddEditIssue,
  },
  data() {
    return {
      isNewIssueModalActive: false,
      issues: [],
      isLoading: false,
    }
  },
  mounted() {
    // Obtem todos os pedidos associados ao utilizador
    this.getIssues()

    // Abre modal para criar novo pedido caso o utilizador
    // tenha sido redirecionado da página de candidatura
    if (this.$route.query.openNew) {
      setTimeout(() => {
        this.isNewIssueModalActive = true
        // Elimina os atributos no url para não apresentar a modal após reload
        this.$router.replace({ ...this.$router.currentRoute, query: {} })
      }, 200)
    }
  },
  methods: {
    closeNewIssueModal(reload = false) {
      if (reload) {
        this.getIssues()
      }
      this.isNewIssueModalActive = false
    },
    async getIssues() {
      this.isLoading = true
      const issues = await SupportService.getIssuesAsOwner(this.$store.getters.getUser.key)
      this.issues = Object.freeze(issues)
      this.isLoading = false
    },
  },
}
</script>
