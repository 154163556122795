<template>
  <b-modal :active.sync="isActive" scroll="keep" :on-cancel="closeModal">
    <div class="panel">
      <div class="panel-header">
        <h1>{{ $t('support.newTicket') }}</h1>
      </div>
      <div class="panel-body">
        <b-field class="has-margin-top-bottom" :label="$t('support.ticket.subject')">
          <b-input v-model="subject" autofocus :placeholder="$t('support.ticket.subject')" size="is-large"></b-input>
        </b-field>
        <b-field :label="$tc('procedure.label')">
          <multiselect
            v-model="procedure"
            :options="procedures"
            :show-labels="false"
            :clear-on-select="false"
            :allow-empty="false"
            :placeholder="$t('support.ticket.procedurePholder')"
            label="code"
            track-by="code"
            :max-height="200"
          >
            <template slot="option" slot-scope="props">
              {{ props.option.code
              }}<span v-if="props.option.number_of_seats"> - {{ props.option.number_of_seats + 'x' }}</span>
              {{ props.option.professional_category_title }}
              {{ props.option.department_title || props.option.faculty_title }}
            </template>
          </multiselect>
        </b-field>
        <b-field :label="$tc('support.messages.title')" :message="$t('support.ticket.messagePholder')">
          <div class="html-editor">
            <editor-menu-bar v-slot="{ commands, isActive, focused }" :editor="editor">
              <div class="menubar" :class="{ 'is-focused': focused }">
                <button
                  class="button is-text is-small"
                  :class="{ 'is-active': isActive.bold() }"
                  @click="commands.bold"
                >
                  <icon name="bold" />
                </button>

                <button
                  class="button is-text is-small"
                  :class="{ 'is-active': isActive.italic() }"
                  @click="commands.italic"
                >
                  <icon name="italic" />
                </button>

                <button
                  class="button is-text is-small"
                  :class="{ 'is-active': isActive.underline() }"
                  @click="commands.underline"
                >
                  <icon name="underline" />
                </button>

                <button
                  class="button is-text is-small"
                  :class="{ 'is-active': isActive.code() }"
                  @click="commands.code"
                >
                  <icon name="code" />
                </button>

                <button
                  class="button is-text is-small"
                  :class="{ 'is-active': isActive.paragraph() }"
                  @click="commands.paragraph"
                >
                  <icon name="paragraph" />
                </button>

                <button
                  class="button is-text is-small"
                  :class="{ 'is-active': isActive.code_block() }"
                  @click="commands.code_block"
                >
                  <icon name="code" />
                </button>

                <button class="button is-text is-small" @click="commands.undo">
                  <icon name="undo" />
                </button>

                <button class="button is-text is-small" @click="commands.redo">
                  <icon name="redo" />
                </button>
              </div>
            </editor-menu-bar>
            <editor-content :editor="editor" />
          </div>
        </b-field>
        <b-field>
          <Uploader
            :files.sync="filesToUpload"
            reference-id="upload"
            input-id="upload"
            path-to-upload="/v1/issue/tmp-files"
            @upload="updateFilesCart"
          />
        </b-field>
        <b-field class="has-margin-top-medium">
          <button class="button is-black" :class="{ 'is-primary': isValid }" :disabled="!isValid" @click="submitIssue">
            {{ $t('actions.send') }}
          </button>
          <button class="button is-text" @click="closeModal">
            {{ $t('actions.cancel') }}
          </button>
        </b-field>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { CodeBlock, Bold, Code, Italic, Link, Strike, Underline, History, Placeholder } from 'tiptap-extensions'

import Multiselect from 'vue-multiselect'
import Uploader from '@/components/utilities/Uploader'
import SupportService from '@/services/SupportService'
import ProcedureService from '@/services/ProcedureService'

export default {
  components: {
    EditorContent,
    EditorMenuBar,
    Uploader,
    Multiselect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: this.show || false,
      editor: new Editor({
        extensions: [
          new CodeBlock(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Placeholder({
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.$t('support.ticket.messagePholder') + '...',
            showOnlyWhenEditable: true,
          }),
        ],
        onUpdate: ({ getHTML }) => {
          this.description = getHTML()
        },
      }),
      subject: '',
      procedure: null,
      description: '',
      filesToUpload: [],
      filesToUploadSessionId: [],
      procedures: [],
    }
  },
  computed: {
    isValid() {
      return this.subject && this.procedure && this.description.length > 5 // tem sempre html
    },
  },
  watch: {
    show(value) {
      this.isActive = value
    },
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  mounted() {
    this.getProcedures(true)
  },
  methods: {
    async getProcedures(defineSelected = false) {
      const proceduresResponse = await ProcedureService.getProcedures()
      this.procedures = proceduresResponse.data // todos os procedimentos obtidos

      // Define o procedimento default no formulário, caso o utilizador tenha sido redirecionado de outra vista
      if (defineSelected && this.procedures && this.$route.query.procedureCode) {
        const requested = this.procedures[this.procedures.findIndex(p => p.code == this.$route.query.procedureCode)]
        if (!this.procedure && requested) {
          this.procedure = requested
        }
      }
    },

    clearModal() {
      this.subject = ''
      this.description = ''
      this.procedure = null
      this.filesToUpload = []
      this.editor.destroy()
    },

    closeModal() {
      this.$emit('close')
      this.isActive = false
    },

    updateFilesCart(files) {
      this.filesToUpload = files
      // this.filesToUpload[0].response.data.session_id
    },

    async submitIssue() {
      this.filesToUpload.forEach(item => {
        this.filesToUploadSessionId.push(item.response.data.session_id)
      })

      const issue = await SupportService.submitIssue({
        subject: this.subject,
        description: this.description,
        code: this.procedure.code,
        files: this.filesToUploadSessionId,
      })
      if (issue) {
        this.$buefy.snackbar.open({
          message: this.$t('support.ticket.sent'),
          type: 'is-primary',
          duration: 5000,
        })
        this.$emit('close', true)
        this.clearModal()
      }
    },
  },
}
</script>
